import { z } from "zod"

export const RecipeSchema = z.object({
  container_product_id: z.number(),
  fragrance_product_id: z.number(),
  wicks_per_container: z.number().optional(),
  wax_product_id: z.number(),
  wick_product_id: z.number().optional(),
  strength: z.number(),
  quantity: z.number(),
})

export type MscRecipe = z.infer<typeof RecipeSchema>
