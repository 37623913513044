<template>
  <ContentWithSidebar>
    <Container>
      <Headline>{{ title }}</Headline>
      <RichText v-if="description" :content="description" />
    </Container>
    <template #aside>
      <div class="candle-recipe-sidebar">
        <Headline :level="4" no-margin>{{ sidebarTitle }}</Headline>
        <span class="candle-recipe-sidebar-description">
          {{ sidebarDescription }}
        </span>
        <div
          v-if="Object.keys(products).length > 0"
          class="candle-recipe-products"
        >
          <div
            v-for="product in products"
            :key="product?.id"
            class="candle-recipe-product"
          >
            <img
              class="item-image"
              :src="product?.productImageUrl"
              role="presentation"
              loading="lazy"
              :alt="product?.productImageAlt"
            />
            <div class="item-body">
              <div class="item-name">
                {{ product?.productName }}
              </div>
            </div>
          </div>
        </div>
        <span class="candle-recipe-label">Amount of candles</span>
        <div class="quant-radio">
          <template v-for="option in QUANT_OPTIONS" :key="option">
            <input
              :id="`radio-${option}`"
              v-model="quant"
              class="quant-radio-input"
              type="radio"
              :value="option"
              name="quant"
            />
            <label
              :htmlFor="`radio-${option}`"
              class="quant-radio-label"
              :class="`radio-${option}`"
            >
              {{ option }}
            </label>
          </template>
        </div>
        <Button :loading="loading" @click="addToCart">
          Add supplies to cart
        </Button>
      </div>
    </template>
  </ContentWithSidebar>
</template>

<script setup lang="ts">
  import { useCartStore } from "~/pinia/cart"

  import {
    type MscRecipe,
    RecipeSchema,
  } from "~/types/materialSupplyCalculatorSchema"

  const props = defineProps<{ element: AlchemyElement }>()
  const { api } = useApi()
  const { getValue, getIngredient } = useAlchemy()
  const quant = ref(12)

  const store = useCartStore()
  const loading = ref(false)
  const QUANT_OPTIONS = [12, 24, 48]
  const { handleError } = useErrorHandling()

  const title = getValue(props.element, "title")
  const description = getValue<string>(props.element, "description")

  const sidebarTitle = getValue<string>(props.element, "sidebar_title")
  const sidebarDescription = getValue<string>(
    props.element,
    "sidebar_description",
  )

  const addToCartText = getValue<string>(props.element, "add_to_cart_text")

  const products = {
    container: getIngredient<AlchemyIngredienSpreeProduct>(
      props.element,
      "container",
    ),
    fragrance: getIngredient<AlchemyIngredienSpreeProduct>(
      props.element,
      "fragrance",
    ),
    wax: getIngredient<AlchemyIngredienSpreeProduct>(props.element, "wax"),
    wick: getIngredient<AlchemyIngredienSpreeProduct>(props.element, "wick"),
  }
  const productIds = {
    container: getValue<string>(props.element, "container"),
    fragrance: getValue(props.element, "fragrance"),
    wax: getValue(props.element, "wax"),
    wick: getValue(props.element, "wick"),
  }

  const buildStrength = () => {
    const strength = getValue<string>(props.element, "strength")
    if (strength) {
      return parseInt(strength) / 100
    }
  }

  const addToCart = async () => {
    loading.value = true

    const recipe: MscRecipe = RecipeSchema.parse({
      container_product_id: productIds.container,
      fragrance_product_id: productIds.fragrance,
      wax_product_id: productIds.wax,
      wick_product_id: productIds.wick,
      strength: buildStrength(),
      quantity: quant.value,
    })

    try {
      const data = await api<MscShoppingList>(
        "/cs/api/material_supply_calculations",
        {
          params: recipe,
        },
      )
      const cartItems: CartItem[] = data.variants.map((item) => ({
        variant_id: `${item.variant_id}`,
        quantity: item.quantity,
      }))
      await store.addToCart(cartItems, addToCartText || "")
    } catch (error) {
      handleError(error)
    } finally {
      loading.value = false
    }
  }
</script>

<style scoped lang="scss">
  .candle-recipe-sidebar {
    display: flex;
    flex-direction: column;
    grid-area: sidebar;
    padding: $gutter;
    border: 1px solid $gray-200;
    box-shadow: $shadow;
    border-radius: $border-radius;
  }

  .candle-recipe-sidebar-description {
    color: $gray-text;
    margin-bottom: $gutter;
  }

  .candle-recipe-products {
    display: flex;
    flex-direction: column;
    grid-gap: $space-s;
    margin-bottom: $space-m;
  }

  .candle-recipe-product {
    display: flex;
    align-items: center;
  }

  .candle-recipe-label {
    font-weight: bold;
  }

  .item-image {
    position: relative;

    display: block;
    flex-shrink: 0;
    width: $base-spacing * 10;
    height: $base-spacing * 10;
    border-radius: $border-radius;
    object-fit: cover;
    margin-right: $base-spacing * 4;
    box-shadow: $shadow;
    &:before {
      background: $gray;
    }
  }

  .item-name {
    font-weight: bold;
  }
  .item-info {
    color: $gray-text;
  }

  .quant-radio {
    position: relative;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: $space-s;
  }

  .quant-radio-input {
    position: absolute;
    // appearance: none;
    opacity: 0;
    &:checked + .quant-radio-label {
      background: $gray-200;
    }
  }
  .quant-radio-label {
    background: $gray-100;
    text-align: center;
    padding: $base-spacing * 2;
    border-bottom: 1px solid $gray-300;
    border-top: 1px solid $gray-300;
    border-right: 1px solid $gray-300;

    transition: background-color 180ms ease-in-out;
  }

  .radio-12 {
    border-left: 1px solid $gray-300;
    border-radius: $border-radius 0 0 $border-radius;
  }
  .radio-48 {
    border-radius: 0 $border-radius $border-radius 0;
  }
</style>
